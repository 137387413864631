import React, { useState, useEffect } from 'react';
// 导入所有数字图片
import zero from '../assets/0.png';
import one from '../assets/1.png';
import two from '../assets/2.png';
import three from '../assets/3.png';
import four from '../assets/4.png';
import five from '../assets/5.png';
import six from '../assets/6.png';
import seven from '../assets/7.png';
import eight from '../assets/8.png';
import nine from '../assets/9.png';
import colon from '../assets/colon.png';
import { RxEnterFullScreen, RxExitFullScreen } from "react-icons/rx";

function Clock() {
  const [time, setTime] = useState(new Date());
  const [isFullscreen, setIsFullscreen] = useState(false);

  // 数字图片映射
  const digitImages = {
    '0': zero,
    '1': one,
    '2': two,
    '3': three,
    '4': four,
    '5': five,
    '6': six,
    '7': seven,
    '8': eight,
    '9': nine
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatNumber = (num) => {
    return num.toString().padStart(2, '0');
  };

  const hours = formatNumber(time.getHours());
  const minutes = formatNumber(time.getMinutes());
  const seconds = formatNumber(time.getSeconds());

  const timeArray = `${hours}${minutes}${seconds}`.split('');

  // 处理全屏
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch(err => console.log(err));
    } else {
      document.exitFullscreen()
        .then(() => setIsFullscreen(false))
        .catch(err => console.log(err));
    }
  };

  return (
    <div className="clock-container">
      <button className="fullscreen-btn" onClick={toggleFullScreen}>
        {isFullscreen ? 
          <RxExitFullScreen className="fullscreen-icon" /> : 
          <RxEnterFullScreen className="fullscreen-icon" />
        }
      </button>
      <div className="digital-clock">
        {timeArray.map((digit, index) => (
          <React.Fragment key={index}>
            <img 
              src={digitImages[digit]} 
              alt={digit} 
              className="digit"
            />
            {(index === 1 || index === 3) && (
              <img 
                src={colon} 
                alt=":" 
                className="colon"
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Clock; 