import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import './App.css';
// 导入需要的图标
import { FaClock } from 'react-icons/fa';
import { GoSidebarCollapse } from "react-icons/go";

// 导入页面组件
import Clock from './pages/Clock';

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // 监听全屏变化
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Router>
      <div className={`app-container ${isFullscreen ? 'fullscreen-mode' : ''}`}>
        <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <div className="sidebar-header">
            <h1 className="logo">MultiTools</h1>
            <button className="collapse-btn" onClick={toggleSidebar}>
              <GoSidebarCollapse className={`collapse-icon ${isCollapsed ? 'rotated' : ''}`} />
            </button>
          </div>
          
          <div className="nav-links">
            <NavLink to="/clock" className={({isActive}) => 
              `nav-item ${isActive ? 'active' : ''}`
            }>
              <FaClock className="icon" />
              <span className="nav-text">Clock</span>
            </NavLink>
          </div>
        </nav>

        <main className="main-content">
          <Routes>
            <Route path="/" element={<Navigate to="/clock" replace />} />
            <Route path="/clock" element={<Clock />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;